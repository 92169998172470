:root {
  --primary-color: #fffff;
  --primary-color-800: #fffff;
  --primary-color-700: #fffff;
  --primary-color-600: #fffff;
  --primary-color-500: #fffff;
  --primary-color-400: #fffff;
  --primary-color-300: #fffff;
  --primary-color-200: #fffff;
  --primary-color-100: #fffff;
  --primary-color-50: #fffff;
  --primary-color-10: #fffff;

  --secondary-color-900: #fffff;
  --secondary-color-800: #fffff;
  --secondary-color-700: #fffff;
  --secondary-color-600: #fffff;
  --secondary-color-500: #fffff;
  --secondary-color-400: #fffff;
  --secondary-color-300: #fffff;
  --secondary-color-200: #fffff;
  --secondary-color-100: #fffff;
  --secondary-color-50: #fffff;

  --brand-color-900: #fffff;
  --brand-color-800: #fffff;
  --brand-color-700: #fffff;
}
