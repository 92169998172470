@import 'variables.scss';

//Scroll
::-webkit-scrollbar{
  width: 0.7rem;
  height: 0.7rem;
  @media only screen and (max-width: 768px) {
    width: 0.5rem;
    height: 0.5rem;
  }
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: var(--secondary-color-400);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-color-600);
  border: 3px solid var(--secondary-color-600);
}

.Carousel{
  &__header{
    display: flex;
    margin-bottom: .5rem;

    @media only screen and (max-width: 768px) {
      min-height: 6rem;
    }

    &--number{
      display: flex;
      flex-direction: column;
    }

    span{
      background-color:var(--secondary-color-400);
      color: white;

      font-size: 1.5rem;
      text-align: center;
      padding: .5rem .75rem;
      margin-right: 1rem;
      font-weight: 700;
      border-radius: 50%;
    }

    p {
      font-weight: 600;
    }
  }

  &__slide{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

  }

  &__button{
    position: absolute;
    cursor: pointer;
    background-color:var(--secondary-color-400);
    padding: .25rem .25rem;
    border-radius: 50%;

    &--right{
      display: flex;
      right: .5rem;
    }

    &--left{
      background-color:#cab09e7c;
      left: .5rem;
    }
  }

  &__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2rem;
    margin-top: .5rem;
  }

  &__dots{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 0 1 150px;
  }

  &__tooltipContainer {
    position: relative;
    display: inline-block;
  }

  &__tooltip{

    color: white !important;
    background-color: var(--secondary-color-600) !important;
    border-radius: 10px !important;

    width: 200px;
    bottom: 150%;
    left: 50%;
    margin-left: -100px;

    text-align: center;
    font-weight: 600;
    padding: 1rem 0;

    @media screen and (max-width: 768px) {
      width: 70px;
      bottom: 125%;
      left: 50%;
      padding: .5rem 0;
      margin-left: -35px;
      font-size: .5rem;
    }


    position: absolute;
    z-index: 1;

    &::after{
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: var(--secondary-color-600) transparent transparent transparent;
    }
  }

  &__image{
    &--web{
      max-height: 50vh;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--mobile{
      max-height: 50vh;
      @media screen and (min-width: 769px) {
        display: none;
      }
    }
  }

  &__footer-button {
    display: flex;
    justify-content: center;
  }
}

.about-entry {
  &__checkbox-terms {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &__title-terms {
    text-align: center;
    color: var(--secondary-color-400);
    font-size: 1.75rem;
    font-weight: 500;

  }
}

.topic-terms {
  color: var(--secondary-color-400);
  font-size: 1.5rem;
  margin-top: 0.7rem;
  font-weight: 500;
}

.chakra-checkbox__control {
  border: 1px solid #bbb !important;
  background-color: #fff;
}

.chakra-checkbox__control[data-checked] {
  background: var(--secondary-color-400) !important;
}

.chakra-checkbox__control[data-invalid] {
  border-color: red !important;
}

.chakra-checkbox__control[data-focus] {
  box-shadow: 0 0 3px var(--secondary-color-400) !important;
}

.modal-terms {
  &__content {
    height: 75vh;
    overflow-y: auto;
    max-width: 50% !important;

    @media screen and (max-width: 768px) {
      max-width: 100% !important;
    }
  }
}

.checkbox-error {
  color: rgb(255, 0, 0);
  font-size: 0.8em;
}

.finish-with-link {
  padding: 16px 32px 32px;
  
  @media screen and (max-width: 480px) {
    font-size: 14px;
    padding: 0;
    padding-bottom: 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  &__main-icon {
    margin: 16px auto;

    height: 140px;
    width: 140px;
    
    @media screen and (max-width: 480px) {
      height: 100px;
      width: 100px;
    }

    &--error { 
      margin-top: 0;
      height: 300px;
      width: 300px;

      @media screen and (max-width: 480px) {
        height: 200px;
        width: 220px;
      }
    }

    &--load {
      height: 280px;
      width: 280px;

      @media screen and (max-width: 480px) {
        height: 180px;
        width: 180px;
      }
    }
  }

  &__body-container {
    width: 100%;
    text-align: center;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }

  &__paragraph {
    margin-bottom: 20px;
    text-align: center;

    &--error {
      padding: 0 16px;
      margin-bottom: 0;
    }
  }

  &__link-container {
    display: flex;
    width: 100%;
  }

  &__link {
    align-items: center;
    background-color: white;
    border: 1px solid var(--secondary-color-400);
    border-right: none;
    border-radius: 12px 0 0 12px;
    color: black;
    display: flex;
    font-weight: 500;
    justify-content: center;
    padding: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      padding: 4px;
      font-size: 14px;
    }
    
    &:hover {
      text-decoration: underline;
      color: var(--primary-color-700)
    }
  }

  &__share-icon-container {
    cursor: pointer;
    border: 1px solid var(--secondary-color-400);
    border-radius: 0 12px 12px 0;
    background-color: var(--secondary-color-400);
    padding: 6px;

    &:hover {
      background-color: var(--brand-color-900);
    }
  }

  &__share-icon {
    height: 28px;
    width: 28px;

    @media screen and (max-width: 480px) {
      height: 28px;
      width: 28px;
      padding: 2px;
    }
  }

  &__skeleton {
    width: 80%;
    height: 45px;
    margin: 16px auto 24px;

    @media screen and (max-width: 480px) {
      height: 35px;
    }
  }

  &__footer-button {
    margin-top: 16px;
    text-align: center;
    width: 100%;
  }
}